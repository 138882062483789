<template lang="pug">
v-card(max-width="600")
  v-card-title.d-flex.justify-space-between
    span Добавление получателя
    v-btn(
      icon
      @click="$emit('close')"
    )
      v-icon fa-times
  v-card-text
    v-radio-group.mb-5(
      row
      v-model="contactType"
      hide-details
      :disabled = "showWarning"
    )
      v-radio(
        value="email"
        label="Email"
      )
      v-radio(
        value="phone"
        label="Телефон"
      )

    v-text-field.mb-5(
      v-if="contactType === 'email'"
      key="email"
      v-model="email"
      label="Email"
      :error-messages="getValidationErrors('email') || getValidationErrors('validContact')"
      hide-details="auto"
      :disabled = "showWarning"
    )

    v-text-field.mb-5(
      v-if="contactType === 'phone'"
      key="phone"
      v-model="phone"
      label="Телефон"
      v-mask="'+7 (###) ###-##-##'"
      placeholder="+7 (___) ___-__-__"
      :error-messages="getValidationErrors('phone')"
      hide-details="auto"
      :disabled = "showWarning"
    )

    SelectNotificationTypes.mb-5(
      v-model="selectedTypes"
      :items="typesNotif"
      :error-messages="getValidationErrors('selectedTypes')"
      :disabled = "showWarning"
    )

    v-radio-group.my-5(
      v-model="radioType"
      row
      hide-details
      :disabled = "showWarning"
    )
      v-radio(
        label="Организация"
        value="org"
      )
      v-radio(
        label="Хостнейм"
        value="host"
      )

    v-autocomplete.mb-5(
      v-if="radioType === 'org'"
      v-model="selectedOrgId"
      label="Организация"
      placeholder="Выберите организацию"
      :items="orgList"
      item-value="id"
      item-text="name"
      :loading="$root.isLoadingGlobal"
      :error-messages="getValidationErrors('selectedOrgId')"
      :disabled = "showWarning"
    )
    v-autocomplete.mb-5(
      v-if="radioType === 'host'"
      v-model="selectedHostName"
      label="Хостнейм"
      placeholder="Выберите хостнейм"
      :items="hostList"
      item-value="name"
      item-text="name"
      :loading="$root.isLoadingGlobal"
      :error-messages="getValidationErrors('selectedHostName')"
      :disabled = "showWarning"
    )

    div(v-if="showWarning")
      div.text-center.text-h6.font-weight-black.pb-3(style="width: 100%") Такой получатель уже существует!
      ul(v-if="existingRecipient")
        li(v-for="recipient in existingRecipient")
          p.text-left Контакт - {{recipient.contact}}
          p.text-left(v-if="recipient.orgName") Организация - {{recipient.orgName}}
          p.text-left(v-if="recipient.hostName") Хостнейм - {{recipient.hostName}}
          p.text-left(v-if="recipient.typeIds") Активные нотификации - {{recipient.typeNames.active.join(', ')}}
          p.text-left(v-if="recipient.typeIds") Неактивные нотификации - {{recipient.typeNames.notActive.join(', ')}}
          p.text-left Статус - {{recipient.typeIds.active.length ? 'Активный' : 'Неактивный'}}

    v-col.d-flex.justify-end.py-0
      v-btn.text--secondary.mr-3(
        text
        @click="$emit('close')"
      ) Отмена
      v-btn.mr-3(
        v-if = "showWarning"
        @click="returnEnable"
        color="primary"
        depressed
      ) Редактировать данные
      v-btn(
        @click="!showWarning ? checkingBeforeSubmit() : onSubmit()"
        color="primary"
        depressed
      ) {{!showWarning ? 'Сохранить' : 'Обновить получателя'}}   
</template>

<script>
import SelectNotificationTypes from '@/components/controls/SelectNotificationTypes.vue';

import validationMixin from '@/utils/validation';
import { phone } from '@/utils/validators';
import { required, email } from 'vuelidate/lib/validators';

import { mapGetters } from 'vuex';
import { mapGettersMutations } from '@/utils/vuex';

export default {
  components: { SelectNotificationTypes },
  mixins: [validationMixin],
  data() {
    return {
      selectedTypes: [],

      contactType: 'email',
      radioType: 'org',

      selectedOrgId: null,
      selectedHostName: null,

      showWarning: false,
      existingRecipient: [],
    };
  },

  validations() {
    const result = { selectedTypes: { required } };
    if (this.contactType === 'email') {
      result.email = { required, email };
    } else {
      result.phone = { required, phone };
    }

    if (this.radioType === 'org') {
      result.selectedOrgId = { required };
    } else {
      result.selectedHostName = { required };
    }
    return result;
  },

  computed: {
    ...mapGetters('NOTIFICATIONS', ['typesNotif', 'orgList', 'hostList']),

    ...mapGetters('NOTIFICATIONS_LIST', ['items']),

    ...mapGettersMutations('NOTIFICATIONS_ADD', {
      email: 'email',
      phone: 'phone',
    }),
  },

  mounted() {
    if (!this.typesNotif.length) {
      this.$store.dispatch('NOTIFICATIONS/getTypes');
    }

    this.$store.commit('NOTIFICATIONS_ADD/reset');
  },

  methods: {
    checkingBeforeSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$notify({
          group: 'error',
          type: 'error',
          title: `Ошибка валидации`,
          text: '',
        });
        return null;
      }

      const contact =
        this.contactType === 'email'
          ? this.email
          : this.phone.replace(/[+()\s-]/g, '');

      this.existingRecipient = this.items.filter(item => {
        return (
          item.contact === contact &&
          (item.orgId === String(this.selectedOrgId) ||
            item.hostName === this.selectedHostName)
        );
      });

      if (this.existingRecipient.length > 0) {
        this.showWarning = true;
      } else {
        this.onSubmit();
      }
    },

    returnEnable() {
      this.showWarning = false;
    },

    async onSubmit() {
      this.radioType === 'org'
        ? this.$store.commit('NOTIFICATIONS_ADD/orgid', this.selectedOrgId)
        : this.$store.commit(
            'NOTIFICATIONS_ADD/hostname',
            this.selectedHostName,
          );

      // сохраняем выбор в store
      this.$store.commit('NOTIFICATIONS_ADD/types', this.selectedTypes);
      try {
        await this.$store.dispatch('NOTIFICATIONS_ADD/add', this.contactType);
        this.$notify({
          group: 'note',
          type: 'info',
          title: `Новый получатель добавлен`,
          text: '',
        });
        this.$emit('update');
        this.$emit('close');
      } catch (e) {
        this.$notify({
          group: 'error',
          type: 'error',
          title: `Произошла ошибка добавления получателя. Попробуйте позже.`,
          text: '',
        });
      }
    },
  },
};
</script>
