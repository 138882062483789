<template lang="pug">
  v-select(
    :value="value"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    label="Тип нотификаций"
    :dense="dense"
    hide-details="auto"
    multiple
    :error-messages="errorMessages"
    @input="input"
    :disabled="disabled"
  )

    template(#prepend-item)
      v-list-item
        v-list-item-action
          v-simple-checkbox(
            @input="toggleAll"
            :value="!!value.length"
            :indeterminate="!value.length ? false : !allSelected"
          )
        v-list-item-content
          v-list-item-title Выбрать все
      v-divider.mt-2

    template(#selection='{ item, index }')
      span(v-if='index === 0') {{ item.reason }}
      span.ml-3.grey--text.caption(v-if='index === 1') (ещё +{{ value.length - 1 }})
</template>

<script>
export default {
  props: {
    value: { required: true, type: Array },
    items: { required: true, type: Array },
    itemText: { type: String, default: 'reason' },
    itemValue: { type: String, default: 'id' },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },

    dense: { type: Boolean, default: false },
    disabled: Boolean,
  },

  computed: {
    allSelected() {
      return this.value.length === this.items.length;
    },
  },
  methods: {
    toggleAll(event) {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.$emit('input', []);
        } else {
          this.$emit(
            'input',
            this.items.map(v => v.id),
          );
        }
      });
    },
    input(value) {
      this.$emit('input', value);
    },
  },
};
</script>
